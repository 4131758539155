<template>
  <v-row justify="center" id="payment-mSuccess">
    <v-flex
        md12
    >
      <v-card-text class="text--primary"
                   style="text-align: center!important;font-size: 18px !important;margin-top: 50px">

        <v-icon style="color: green;font-size: 200px">mdi-progress-check</v-icon>
        <h1 style="padding-bottom: 20px;padding-top: 50px;color: teal;line-height: 50px">{{ $t("paidSuccess") }}</h1>
        <h2 style="padding-bottom: 30px;color: blueviolet">{{ $t("thankYou") }}</h2>
<!--        <v-btn depressed @click="hideBtn" x-large>-->
<!--          Done-->
<!--        </v-btn>-->
      </v-card-text>
    </v-flex>
  </v-row>
</template>

<script>
export default {
  name: "PaymentSuccess",
  data() {
    return {}
  },
  mounted() {
    this.$jQuery('body').off();
    // this.$nextTick(() => {
    //   this.$jQuery('body').on('keydown', "#dr", this.validateNum);
    //   this.$jQuery('body').on('keydown', "#cr", this.validateNum);
    //   this.$jQuery('body').on('keydown', "#amount", this.validateNum);
    // })
  },
  methods: {
    hideBtn() {
      let modal = document.getElementById('payment-mSuccess');
      modal.style.display = "none";
    }
  },
  created() {
  }
}

</script>